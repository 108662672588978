const data = {
  title1: "World-leading DDoS mitigation brand",
  title2: 'Automatically cleans global DDoS attacks and protects commercial applications for permanent availability.',
  desc1: `With comprehensive, efficient and professional DDoS protection capabilities, Zenlayer provides a variety of DDoS solutions for enterprise organizations to deal with DDoS attacks.
  220+ edge nodes, 1000+ global interconnection, 10T+ protection capability, through sufficient and high-quality DDoS protection resources, combined with the continuously evolving "self research + intelligent identification" cleaning algorithm ensures the stable and safe operation of user services.
  Protection scenarios cover games, Internet, video, finance, government and other industries.`,
  freeTrial: 'Free Trial',
  contact: 'Contact Sales',
  desc2: `In the wave of global digital transformation, enterprises in various industries are extremely vulnerable to DDoS attacks and face the risk of suffering various losses.`,
  desc3: 'It is imperative for enterprises to eliminate the hidden dangers of network security.',
  desc4: 'DDoS Hazard - Damaged Business',
  desc5: 'The origin server cannot operate after be attacked, resulting in users being unable to access services, resulting in huge economic losses and brand losses.',
  desc6: 'DDoS Hazard - Data Breach',
  desc7: 'DDoS attacks may lead to criminal activities such as core data theft, virus infection, and malicious deception, posing great security risks.',
  desc8: 'DDoS Hazard - Damaged Image',
  desc9: 'The inaccessibility of the server or the slow loading of the page will lead to problems such as the decline of user experience and the increase of user complaints, and the corporate brand image and market reputation will be seriously damaged.',
  desc10: 'Complete cleaning and reliable protection for any device connected to the Internet.',
  desc11: 'Provides a complete DDoS protection solution designed to protect everything on your cloud and on-premises network.',
  desc12: 'Built for web applications',
  desc13: `ZDP provides unmetered, always-on DDoS protection for web properties (HTTP/HTTPs). Works with our cloud web application firewall (WAF), automated program management and other L3/4 security services to protect assets from cyber threats.`,
  desc14: 'Excellent protection to ensure business availability',
  desc15: 'Automatically mitigate any DDoS attack in seconds, with layered scrubbing and zero error blocking. Meet the protection requirements of various ports, protect the permanent availability of commercial applications, and achieve secure access with a delay of less than 25ms for 95% of global Internet users.',
  desc16: 'Build for network infrastructure',
  desc17: 'ZDP provides BGP-based DDoS protection for network infrastructure, with data centers in 220 cities in 100 countries advertising customer subnets to absorb network traffic and mitigate threats close to the source of the attack.',
  desc18: 'Why Enterprises Choose Zenlayer DDoS?',
  item1: 'DDoS Defense',
  item2: ' TB-level traffic cleaning, defense attack types include ICMP Flood, UDP Flood, TCP Flood, SYN Flood, ACK Flood and other three to seven layers of DDoS attacks',
  item3: 'CC defense',
  item4: 'Adopt intelligent dynamic fingerprint verification module and multi-dimensional attack model matching, effectively filter various types of CC attacks, can filter millions of concurrent CC per second, and the accidental injury is extremely low',
  item5: 'Overseas protection CN2 line',
  item6: 'At the IP layer, overseas CN2 lines achieve fast route convergence in an average of less than 500ms; at the MPLS layer, FRR is deployed on 50 links between core nodes to achieve 50ms protection switching access.',
  item7: 'High Defense CDN',
  item8: 'Effective defense against DDoS and CC attacks, millisecond-level response, intelligent navigation path distribution, always keep multiple nodes online during attacks, and automatically open backup nodes to ensure your business is stable and fast 24/7',
  item9: 'SSL certificate',
  item10: 'Upgrade the "HTTP" to the "HTTPS" protocol, and make the browser mark the website as "secure". The SSL certificate can protect customer privacy, prevent traffic hijacking, and prevent data information tampering',
  item11: 'High Defense IP',
  item12: 'Completely filter abnormal CC attack behaviors such as extreme variants, shield penetration, simulation, etc., hide the source station IP, and the perfect monitoring system can make the website quickly switch to the high-defense server node after being attacked',
  item13: 'Cloud Defense Situational Awareness Platform',
  item14: 'Based on the defense system, focusing on data visualization, can seen clearly, prevented and traced',
  item15: 'Game protection',
  item16: 'Distributed cloud protection, hiding source IP, seamless switching, no stuck or disconnected, ignoring network attacks such as DDoS and CC attacks',
  item17: 'Web Application Firewall',
  item18: 'Protect web applications from attacks such as cross-site forgery, cross-site scripting (XSS) and SQL injection',
  freeTrialNow: 'Try it now for free',
  signIn: 'Sign In',
}

export default data