const data = {
  title1: '全球领先的DDoS防护品牌',
  title2: '自动清洗全球DDoS攻击，保护商业应用永久可用',
  desc1: `具有全面、高效、专业的 DDoS 防护能力，为企业组织提供多种 DDoS
  解决方案，应对 DDoS 攻击问题。
  220+边缘节点、1000+全球互联、10T+防护能力，通过充足、优质的 DDoS
  防护资源，结合持续进化的“自研+智能识别”清洗算法，保障用户业务的稳定、安全运行。
  防护场景覆盖游戏、互联网、视频、金融、政府等行业`,
  freeTrial: '免费试用',
  contact: '联系商务',
  desc2: '全球数字化转型浪潮中，各行业企业都极易受到DDoS攻击，面临着遭受多种损失的风险。',
  desc3: '消除网络安全隐患，对企业来说刻不容缓。',
  desc4: 'DDoS危害 - 业务受损',
  desc5: '源站服务器遭到攻击后无法运作，导致用户无法访问业务，从而造成巨大的经济损失和品牌损失。',
  desc6: 'DDoS危害 - 数据泄露',
  desc7: 'DDoS攻击可能导致核心数据被窃取、感染病毒、恶意欺骗等犯罪活动，存在极大的安全风险。',
  desc8: 'DDoS危害 - 形象受损',
  desc9: '服务器无法访问或页面加载缓慢导致用户体验下降、用户投诉增多等问题，企业品牌形象和市场声誉将受到严重损失。',
  desc10: '为连接到互联网的任何设备提供全面清洗与可靠保护。',
  desc11: '提供完整的DDoS防护解决方案，旨在保护您的云和内部部署网络上的一切。',
  desc12: '专为 Web 应用程序打造',
  desc13: `ZDP 为 Web 资产（HTTP/HTTPs）提供未计量、永远在线的 DDoS
  防护。与我们的云 Web 应用程序防火墙（WAF）、自动程序管理和其他
  L3/4 安全服务协同工作，以保护资产免受网络威胁危害。`,
  desc14: '出色防护，保障业务可用',
  desc15: '秒级自动缓解任何DDoS攻击，分层擦洗、零误拦。满足各种端口的防护需求，保护商业应用永久可用，为95%全球互联网用户实现延迟小于25ms的安全访问。',
  desc16: '为网络基础设施打造',
  desc17: 'ZDP 为网络基础设施提供基于 BGP 的 DDoS 防护，遍布 100个国家/地区 220个城市的数据中心公告客户子网，以吸收网络流量并在靠近攻击来源的位置缓解威胁。',
  desc18: '为什么企业选择Zenlayer DDoS？',
  item1: 'DDoS防御',
  item2: 'TB级流量清洗，防御的攻击类型包括 ICMP Flood、UDP Flood、TCP Flood、SYN Flood、ACK Flood 等三到七层DDoS攻击',
  item3: 'CC防御',
  item4: '采用智能动态指纹验证模块及多维攻击模型匹配，有效过滤各种类型的CC攻击，每秒可过滤百万并发CC，误伤极低',
  item5: '海外防护CN2线路',
  item6: '海外CN2线路在IP层，实现平均小于500ms的快速路由收敛；在MPLS层，核心节点之间50条链路部署了FRR，实现50ms的保护切换访',
  item7: '高防CDN',
  item8: '有效防御DDoS、CC攻击，毫秒级响应，智能导航路径分发，攻击中也始终保持多节点在线、自动开启备用节点，保证您的业务7X24小时稳定、快速',
  item9: 'SSL证书',
  item10: '将网址中的“HTTP”升级为“HTTPS”协议，并使浏览器对网站加注“安全”标识。可保障客户隐私，防流量劫持，防数据信息篡改',
  item11: '高防IP',
  item12: '完全过滤极端变种、穿盾、模拟等异常CC攻击行为，隐藏源站IP，完善的监控体系可以使网站遭受攻击后快速切换至高防服务器节点',
  item13: '云防御态势感知平台',
  item14: '基于防御体系构建而成，侧重数据可视化，做到看得清、防得住、追得到',
  item15: '游戏防护',
  item16: '分布式云端防护，隐藏源IP，无缝切换，不卡不掉线、无视DDoS、CC攻击等网络攻击',
  item17: 'Web应用防火墙',
  item18: '保护web应用程序免受诸如跨站点伪造、跨站点脚本编制(XSS)和SQL注入攻击',
  freeTrialNow: '立即免费试用',
  signIn: '登录',
}

export default data