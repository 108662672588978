// import React from 'react';
// import ReactDOM from 'react-dom';
// import App from './App';
// import reportWebVitals from './reportWebVitals';

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

// BrowserRouter:<BrowserRouter>使用常规URL路径。 这些通常是外观最好的网址，但它们要求您的服务器配置正确。 具体来说，您的Web服务器需要在所有由React Router客户端管理的URL上提供相同的页面。Create React App在开发中即开即用地支持此功能
// HashRouter:<HashRouter>将当前位置存储在URL的哈希部分中，因此URL看起来类似于http://example.com/#/your/page。 由于哈希从不发送到服务器，因此这意味着不需要特殊的服务器配置。

import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { HashRouter, BrowserRouter } from "react-router-dom";
import { IntlProvider, FormattedMessage, } from "react-intl";

import "./index.css";
import "../src/utils/gt";

import zh_CN from "./locales/zh.js";
import en_US from "./locales/en.js";
import zh_TW from "./locales/tw.js";

// 判断要显示的语言
if (!localStorage.currentLang) {
  let browserLang = navigator?.language?.toLowerCase() || "";
  if (browserLang.indexOf("tw") > -1 || browserLang.indexOf("hk") > -1) {
    localStorage.currentLang = "zh-TW";
  } else if (browserLang.indexOf("zh") > -1) {
    localStorage.currentLang = "zh";
  } else {
    localStorage.currentLang = "en";
  }
}

const messages: any = {
  en: en_US,
  zh: zh_CN,
  "zh-TW": zh_TW,
};

const currentLang = localStorage.currentLang;

ReactDOM.render(
  <BrowserRouter>
    <IntlProvider locale={currentLang} messages={messages[currentLang]} defaultLocale="en">
      {/* 使用了路由懒加载，所以需要使用<Suspense>包起来 */}
      <Suspense fallback={<div></div>}>
        <App />
      </Suspense>
    </IntlProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
