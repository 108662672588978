const data = {
  title1: '全球領先的DDoS防護品牌',
  title2: '自動清洗全球DDoS攻擊，保護商業應用永久可用',
  desc1: `具有全面、高效、專業的 DDoS 防護能力，為企業組織提供多種 DDoS 解決方案，應對 DDoS 攻擊問題。
  220+邊緣節點、1000+全球互聯、10T+防護能力，通過充足、優質的 DDoS 防護資源，結合持續進化
  的“自研+智能識別”清洗算法，保障用戶業務的穩定、安全運行。
  防護場景覆蓋遊戲、互聯網、視頻、金融、政府等行業`,
  freeTrial: '免費試用',
  contact: '聯繫商務',
  desc2: '全球數字化轉型浪潮中， 各行業企業都極易受到DDoS攻擊，面臨著遭受多種損失的風險。',
  desc3: '消除網絡安全隱患，對企業來說刻不容緩。',
  desc4: 'DDoS危害 - 業務受損',
  desc5: '源站服務器遭到攻擊後無法運作，導致用戶無法訪問業務，從而造成巨大的經濟損失和品牌損失。',
  desc6: 'DDoS危害 - 數據洩露',
  desc7: 'DDoS攻擊可能導致核心數據被竊取、感染病毒、惡意欺騙等犯罪活動，存在極大的安全風險。',
  desc8: 'DDoS危害 - 形象受損',
  desc9: '服務器無法訪問或頁面加載緩慢導致用戶體驗下降、用戶投訴增多等問題，企業品牌形象和市場聲譽將受到嚴重損失。',
  desc10: '為連接到互聯網的任何設備提供全面清洗與可靠保護。',
  desc11: '提供完整的 DDoS 防護解決方案，旨在保護您的雲和內部部署網絡上的一切。',
  desc12: '專為 Web 應用程序打造',
  desc13: `ZDP 為 Web 資產（HTTP/HTTPs）提供未計量、永遠在線的 DDoS 防護。與我們的雲 Web 應用程序防火牆（WAF）、自動程序管理和其他 L3/4 安全服務協同工作，以保護資產免受網絡威脅危害。`,
  desc14: '出色防護，保障業務可用',
  desc15: '秒級自動緩解任何DDoS攻擊，分層擦洗、零誤攔。滿足各種端口的防護需求，保護商業應用永久可用，為95%全球互聯網用戶實現延遲小於25ms的安全訪問。',
  desc16: '為網絡基礎設施打造',
  desc17: 'ZDP 為網絡基礎設施提供基於 BGP 的 DDoS 防護，遍布 100 個國家/地區 220 個城市的數據中心公告客戶子網，以吸收網絡流量並在靠近攻擊來源的位置緩解威脅。',
  desc18: '為什麼企業選擇Zenlayer DDoS？',
  item1: 'DDoS防禦',
  item2: 'TB級流量清洗，防禦的攻擊類型包括 ICMP Flood、UDP Flood、TCP Flood、SYN Flood、ACK Flood 等三到七層DDoS攻擊',
  item3: 'CC防禦',
  item4: '採用智能動態指紋驗證模塊及多維攻擊模型匹配，有效過濾各種類型的CC攻擊，每秒可過濾百萬並發CC，誤傷極低',
  item5: '海外防護CN2線路',
  item6: '海外CN2線路在IP層，實現平均小於500ms的快速路由收斂；在MPLS層，核心節點之間50條鏈路部署了FRR，實現50ms的保護切換訪',
  item7: '高防CDN',
  item8: '有效防禦DDoS、CC攻擊，毫秒級響應，智能導航路徑分發，攻擊中也始終保持多節點在線、自動開啟備用節點，保證您的業務7X24小時穩定、快速',
  item9: 'SSL證書',
  item10: '將網址中的“HTTP”升級為“HTTPS”協議，並使瀏覽器對網站加註“安全”標識。可保障客戶隱私，防流量劫持，防數據信息篡改',
  item11: '高防IP',
  item12: '完全過濾極端變種、穿盾、模擬等異常CC攻擊行為，隱藏源站IP，完善的監控體係可以使網站遭受攻擊後快速切換至高防服務器節點',
  item13: '雲防禦態勢感知平台',
  item14: '基於防禦體系構建而成，側重數據可視化，做到看得清、防得住、追得到',
  item15: '遊戲防護',
  item16: '分佈式雲端防護，隱藏源IP，無縫切換，不卡不掉線、無視DDoS、CC攻擊等網絡攻擊',
  item17: 'Web應用防火牆',
  item18: '保護web應用程序免受諸如跨站點偽造、跨站點腳本編制(XSS)和SQL注入攻擊',
  freeTrialNow: '立即免費試用',
  signIn: '登錄',
}

export default data