import {lazy} from 'react'

// const Home = lazy(() => import('./home'))
// const Details = lazy(() => import('./details'))
// const Successful = lazy(() => import('./Successful'))
// const AttackMap = lazy(() => import('./attackMap'))
const Products = lazy(() => import('./products'))
// import Home from './home'
// import Details from './details'
// import Bosslogin from './bosslogin'
// import Successful from './Successful'

export {
  // Home,
  // Details,
  // Successful,
  // AttackMap
  Products,
}

