import * as allPage from '../pages'

type RouterType = {
  path: string,
  component: React.LazyExoticComponent<any>,
  root: string[],
  notExect?: boolean,
}

const HomeRouter: RouterType = {
  path: '/',
  component: allPage.Products,
  root: [],
}

// const DetailsRouter: RouterType = {
//   path: '/details/:uuid',
//   component: allPage.Details,
//   root: [],
// }

// const Succeed: RouterType = {
//   path: '/showResult/:type',
//   component: allPage.Successful,
//   root: [],
// }

// const AttackMap: RouterType = {
//   path: '/attackMap',
//   component: allPage.AttackMap,
//   root: [],
// }

const Products: RouterType = {
  path: '/products',
  component: allPage.Products,
  root: [],
}


// 总路由
const Routers: RouterType[] = ([
  HomeRouter,
  // DetailsRouter,
  // Succeed,
  // AttackMap,
  Products,
])

export {
  Routers
}
