import {
  withRouter,
  Route,
  Switch,
} from "react-router-dom";
// import { Home } from "./pages";
import { Routers } from "./routers";
import "./App.css";

import { Layout } from "antd";
const { Header, Content, Footer } = Layout;

function App() {
  return (
    <div className="App">
      <Layout className="layout">
        {/* <Header>
        <h1>
        智防故障诊断系统
        </h1>
        </Header> */}
        <Content>
          <Switch>
            {Routers.map((router) => (
              <Route
                exact={!router.notExect}
                key={router.path}
                path={router.path}
                component={router.component}
              ></Route>
            ))}
            {/* 设置默认路由 */}
            {/* <Route path="/" component={Home}></Route> */}
          </Switch>
        </Content>
        {/* <Footer style={{ textAlign: "center" }}>
          Ant Design ©2018 Created by Ant UED
        </Footer> */}
      </Layout>

      {/* <Header /> */}
    </div>
  );
}

export default withRouter(App);
